import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { ApiService } from './api/api.service';

@Injectable()
export class LoginService {
  private OauthLoginEndPointUrl = '/login';  // Oauth Login EndPointUrl to web API
  private _userTypeData: Number;
  private _userData;
  private _loggedUser: Observable<any>;
  constructor(private http: HttpClient, private _apiService: ApiService ) { }

  login(username, password): Observable<any> {
    this._loggedUser = null;
    this._userData = null;
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('application_key', environment.applicationKey);

    return this.http.post(environment.baseApi + this.OauthLoginEndPointUrl, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }

  public logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('permisos');
    this._loggedUser = null;
    this._userTypeData = 0;
  }

  get loggedUser(): Observable<any> {
    if (this._loggedUser) {
      return of(this._loggedUser);
    }

    const params = new HttpParams().set('token', sessionStorage.getItem('token'));
    return this._apiService.getResourceService('usuarios').list(params)
      .pipe(
        map((res) => {
          this._loggedUser = res.objects[0];
          this._userData = res.objects[0];
          return this._loggedUser;
        })
      );
  }

  userType(): Number {
    return this._userData.tipo_usuario;
  }

  userId() {
    return this._userData.id;
  }
}
